<template>
   <div class="row justify-content-center align-items-center">
      <div class="col-12 text-center">
        <h2 class="m-4">
          {{$tc("message.resultHeader", 2)}}
        </h2>
        <i class="fa fa-exclamation-circle result-icon"></i>
        <p class="text-secondary mt-4" v-html="$t('message.cannotNominateAwardFamily')">
        </p>
        </div>
    </div>
</template>

<script>
  export default {
  }
</script>
<style scoped>
.result-icon {
  color: #333;
}
</style>